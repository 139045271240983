import React, { useEffect } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import { useDispatch, useSelector } from 'react-redux';
import { signout } from '../../firebase/actions/userActions';

import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import SellIcon from '@mui/icons-material/Sell';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import GradeIcon from '@mui/icons-material/Grade';
import FeedbackIcon from '@mui/icons-material/Feedback';
import GroupsIcon from '@mui/icons-material/Groups';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

import ProfileMenuItem from './ProfileMenuItem';
import MyFarm from './MyFarm';
import MyMarket from './MyMarket';
import { USER_SIGNOUT_RESET } from '../../firebase/constants/userConstants';
import LoadingCompact from '../LoadingBox/LoadingCompact';
import SmallMessageBox from '../MessageBox/SmallMessageBox';

export default function FullMenu({
    props,
    anchorEl,
    menuId,
    isMenuOpen,
    handleMenuClose,
}) {
    const { currentUser, userType, logout } = useAuth();

    const userSignout = useSelector((state) => state.userSignout);

    const { success, loading, error } = userSignout;
    const dispatch = useDispatch();
    const signoutHandler = () => {
        dispatch(signout(currentUser.uid, userType));
    };

    useEffect(() => {
        const runlogout = async () => {
            if (success) {
                dispatch({ type: USER_SIGNOUT_RESET });
                await logout();
                props.history.push('/signin');
            }
        };
        runlogout();
    }, [success, props.history, dispatch, logout]);

    return (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            {currentUser && (
                <ProfileMenuItem
                    props={props}
                    handleMenuClose={handleMenuClose}
                />
            )}
            {currentUser && userType.isFarmer && (
                <MyFarm
                    props={props}
                    handleMenuClose={handleMenuClose}
                    currentUser={currentUser}
                    userType={userType}
                />
            )}
            {currentUser && userType.isFarmer && (
                <MenuItem
                    onClick={() => {
                        props.history.push(`/sales/${currentUser.uid}`);
                        handleMenuClose();
                    }}
                >
                    <IconButton
                        size="large"
                        aria-label="user sales"
                        color="inherit"
                    >
                        <SellIcon />
                    </IconButton>
                    <p>Sale Listings</p>
                </MenuItem>
            )}
            {currentUser && userType.isTrader && (
                <MyMarket
                    props={props}
                    handleMenuClose={handleMenuClose}
                    currentUser={currentUser}
                    userType={userType}
                />
            )}
            {currentUser && userType.isTrader && (
                <MenuItem
                    onClick={() => {
                        props.history.push(`/purchasing/${currentUser.uid}`);
                        handleMenuClose();
                    }}
                >
                    <IconButton
                        size="large"
                        aria-label="user purchasing"
                        color="inherit"
                    >
                        <SellIcon />
                    </IconButton>
                    <p>Procurement</p>
                </MenuItem>
            )}

            {currentUser && (
                <MenuItem
                    onClick={() => {
                        props.history.push('/orders');
                        handleMenuClose();
                    }}
                >
                    <IconButton
                        size="large"
                        aria-label="user orders"
                        color="inherit"
                    >
                        <LocalShippingIcon />
                    </IconButton>
                    <p>Orders</p>
                </MenuItem>
            )}
            {currentUser && userType.isAdmin && (
                <MenuItem
                    onClick={() => {
                        handleMenuClose();
                        props.history.push('/admin');
                    }}
                >
                    <IconButton
                        size="large"
                        aria-label="user orders"
                        color="inherit"
                    >
                        <AdminPanelSettingsIcon />
                    </IconButton>
                    <p>Admin</p>
                </MenuItem>
            )}
            {currentUser && userType.isAdmin && (
                <MenuItem
                    onClick={() => {
                        handleMenuClose();
                        props.history.push('/grading');
                    }}
                >
                    <IconButton
                        size="large"
                        aria-label="grading"
                        color="inherit"
                    >
                        <GradeIcon />
                    </IconButton>
                    <p>Grading</p>
                </MenuItem>
            )}

            <MenuItem
                onClick={() => {
                    handleMenuClose();
                    props.history.push('/models');
                }}
            >
                <IconButton size="large" aria-label="meetings" color="inherit">
                    <AutoAwesomeIcon />
                </IconButton>
                <p>AI Grading</p>
            </MenuItem>

            <MenuItem
                onClick={() => {
                    handleMenuClose();
                    props.history.push('/meetings');
                }}
            >
                <IconButton size="large" aria-label="meetings" color="inherit">
                    <GroupsIcon />
                </IconButton>
                <p>Meetings</p>
            </MenuItem>

            <MenuItem
                onClick={() => {
                    handleMenuClose();
                    props.history.push('/pricing');
                }}
            >
                <IconButton size="large" aria-label="pricing" color="inherit">
                    <AttachMoneyIcon />
                </IconButton>
                <p>Pricing</p>
            </MenuItem>
            <MenuItem
                onClick={() => {
                    handleMenuClose();
                    props.history.push('/contact-us');
                }}
            >
                <IconButton
                    size="large"
                    aria-label="contact us"
                    color="inherit"
                >
                    <FeedbackIcon />
                </IconButton>
                <p>Feedback</p>
            </MenuItem>
            <MenuItem
                onClick={() => {
                    handleMenuClose();
                    props.history.push('/help');
                }}
            >
                <IconButton size="large" aria-label="help" color="inherit">
                    <HelpOutlineIcon />
                </IconButton>
                <p>Help</p>
            </MenuItem>
            {currentUser ? (
                <MenuItem onClick={signoutHandler}>
                    {loading ? (
                        <LoadingCompact loading="Signing out" />
                    ) : error ? (
                        <SmallMessageBox variant="danger">
                            {error}
                        </SmallMessageBox>
                    ) : (
                        <>
                            <IconButton
                                size="large"
                                aria-label="sign out user"
                                color="inherit"
                            >
                                <PowerSettingsNewIcon />
                            </IconButton>
                            <p>Sign Out</p>
                        </>
                    )}
                </MenuItem>
            ) : (
                <MenuItem
                    onClick={() => {
                        props.history.push('/signin');
                    }}
                >
                    <IconButton
                        size="large"
                        aria-label="sign in user"
                        color="inherit"
                    >
                        <PowerSettingsNewIcon />
                    </IconButton>

                    <p>Sign In</p>
                </MenuItem>
            )}
        </Menu>
    );
}
